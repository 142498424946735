<template>
  <div>
    <p class="small mb-0">
      Deposit into {{ transaction.walletShare.description }} via Stripe
    </p>
  </div>
</template>

<script>
export default {
  props: {
    transaction: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
