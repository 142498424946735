<template>
  <div>
    <p class="small mb-0">
      Bank Transfer to {{ `${transaction.walletShare.wallet.information.alias} (${transaction.walletShare.wallet.information.bankAccount})` }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    transaction: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
