<template>
  <div>
    <p class="small mb-0">
      From {{ transaction.transactionType.source.description }}

      <span v-if="transaction.walletShare.wallet.__typename !== 'BankWallet' && (!transaction.transactionType.destination || transaction.transactionType.destination && transaction.transactionType.destination.__typename !== 'User')">
        <span v-if="userType === 'users' && transaction.walletShare">
          to {{ transaction.walletShare.description }}
        </span>

        <span v-else-if="transaction.transactionType.destination">
          <span v-if="transaction.amount > 0 && transaction.transactionType.destination.uuid === $store.state.project.selectedCompany">
            to {{ transaction.walletShare.description }}
          </span>
        </span>
      </span>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    transaction: {
      type: Object,
      default: () => {},
    },
    userType: {
      type: String,
      default: () => 'users',
    },
  },
}
</script>
