<template>
  <b-modal
    id="delete-company-transaction-modal"
    :title="$t('Delete Transaction')"
    :ok-title="$t('Delete')"
    :cancel-title="$t('Cancel')"
    ok-variant="danger"
    :ok-disabled="isProcessing"
    :cancel-disabled="isProcessing"
    no-close-on-backdrop
    @ok="deleteCompanyTransaction"
  >
    <validation-observer ref="deleteCompanyTransactionForm">
      <b-overlay :show="isProcessing">
        <validation-provider
          #default="{ errors }"
          name="reason"
          rules="required"
        >
          <b-form-group :label="$t('Reason')">
            <b-form-input
              v-model="reason"
              placeholder="Reason"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-overlay>
    </validation-observer>

  </b-modal>
</template>

<script>
import {
  BOverlay, BModal, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import useApollo from '@/plugins/graphql/useApollo'

export default {
  components: {
    BOverlay,
    BModal,
    BFormGroup,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    transactionUid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      reason: '',
      isProcessing: false,
    }
  },
  methods: {
    deleteCompanyTransaction(bvModal) {
      bvModal.preventDefault()
      this.$refs.deleteCompanyTransactionForm.validate().then(success => {
        if (success) {
          this.isProcessing = true
          const params = {
            companyUid: this.$store.state.project.selectedCompany,
            transactionUid: this.transactionUid,
            reason: this.reason,
          }

          useApollo.company.deleteCompanyTransferTransaction(params).then(response => {
            this.showSuccessMessage({
              data: {
                message: response.data.deleteCompanyTransferTransaction.message,
              },
            })
            this.$emit('transaction-deleted')
            this.$bvModal.hide('delete-company-transaction-modal')
          }).catch(error => {
            this.showErrorMessage(error)
          }).finally(() => {
            this.isProcessing = false
          })
        }
      })
    },
  },
}
</script>
