<template>
  <div v-if="actions.length">
    <b-dropdown
      class="pt-1"
      variant="link"
      no-caret
    >
      <template #button-content>
        <b-link>
          <feather-icon icon="MoreVerticalIcon" />
        </b-link>
      </template>

      <b-dropdown-item
        v-for="action in actions"
        :key="action.action"
        :variant="action.variant ? action.variant : ''"
        @click="$emit(action.action)"
      >
        <feather-icon
          :icon="action.icon"
          size="16"
          class="mr-1"
        />
        <span>{{ action.label }}</span>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem, BLink } from 'bootstrap-vue'
import { reconciliationActions, payrollActions, companyWalletToWalletAction } from './statement-actions'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BLink,
  },
  props: {
    transactionType: {
      type: String,
      required: true,
    },
    walletType: {
      type: String,
      required: true,
    },
    destination: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      actions: [],
    }
  },
  mounted() {
    if (this.transactionType && this.walletType) {
      if (this.transactionType === 'ReconciliationTransaction') this.actions = reconciliationActions
      else if (this.walletType === 'PayrollWallet' && this.destination === 'User') this.actions = payrollActions
      else if (this.transactionType === 'WalletToWalletTransfer' && this.destination === 'Company') this.actions = companyWalletToWalletAction
    }
  },
}
</script>
