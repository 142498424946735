<template>
  <b-modal
    id="edit-allocations-modal"
    :title="$t('Update Allocation for Transaction')"
    :ok-title="$t('Update')"
    :cancel-title="$t('Cancel')"
    :ok-disabled="isLoading || isProcessing"
    :cancel-disabled="isLoading || isProcessing"
    no-close-on-backdrop
    @ok="updateAllocation"
    @hidden="allocation = null; $emit('close')"
  >
    <b-overlay :show="isLoading || isProcessing">
      <b-form-group :label="$t('Allocation')">
        <v-select
          v-model="allocation"
          :options="allocationOptions"
          :reduce="wallet => wallet.value"
          :clearable="false"
        />
      </b-form-group>
    </b-overlay>
  </b-modal>
</template>

<script>
import { BFormGroup, BOverlay } from 'bootstrap-vue'
import vSelect from 'vue-select'
import useApollo from '@/plugins/graphql/useApollo'

export default {
  components: {
    BFormGroup,
    BOverlay,
    vSelect,
  },
  props: {
    walletUid: {
      type: String,
      required: true,
    },
    transaction: {
      type: Object,
      required: true,
    },
    allocations: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      allocation: '',
      isProcessing: false,
    }
  },
  computed: {
    allocationOptions() {
      return [...this.allocations]
    },
  },
  watch: {
    transaction: {
      handler(val) {
        if (val) {
          this.allocation = this.transaction.walletShare.uuid
        }
      },
      immediate: true,
    },
  },
  methods: {
    updateAllocation(bvModal) {
      this.isProcessing = true
      bvModal.preventDefault()
      const params = {
        transactionUid: this.transaction.uuid,
        companyUid: this.$store.state.project.selectedCompany,
        allocation: {
          walletUid: this.walletUid,
          shareUid: this.allocation,
        },
      }

      useApollo.company.editCompanyTransactionAllocation(params).then(response => {
        this.showSuccessMessage({
          data: {
            message: response.data.editCompanyTransactionAllocation.message,
          },
        })
        this.$emit('allocation-updated')
        this.$bvModal.hide('edit-allocations-modal')
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
  },
}
</script>
