import jwt from '@/auth/jwt/useJwt'
import {
  companyExporter, companyShareExporter, companyShareStatementQuery, companyStatementQuery,
} from './exportQueries'

const exportStatement = (type, variables, filename) => {
  let query = ''
  let exporter = ''

  if (type === 'company') {
    query = companyStatementQuery
    exporter = companyExporter
  } else {
    query = companyShareStatementQuery
    exporter = companyShareExporter
  }

  const params = {
    query,
    variables,
    exporter,
  }

  return new Promise((resolve, reject) => {
    jwt.exportStatement(params).then(response => {
      if (response.status === 200) {
        const blob = response.data

        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = filename
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        resolve()
      }
    }).catch(e => {
      reject(e)
    })
  })
}

export default exportStatement
