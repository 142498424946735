export const payrollActions = [
  {
    label: 'Edit Allocation',
    icon: 'Edit2Icon',
    action: 'edit-allocation',
  },
]

export const companyWalletToWalletAction = [
  {
    label: 'Delete Transaction',
    icon: 'Trash2Icon',
    action: 'delete-company-transaction',
    variant: 'danger',
  },
]

export const reconciliationActions = [
  {
    label: 'Edit Transaction',
    icon: 'Edit2Icon',
    action: 'update-transaction',
  },
  {
    label: 'Delete Transaction',
    icon: 'Trash2Icon',
    action: 'delete-manual-transaction',
    variant: 'danger',
  },
]
