var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":("reconcile-transaction-" + _vm.index + "-" + _vm.userType + "-modal"),"title":_vm.mode === 'create' ? ("Add Manual Transaction for " + (_vm.wallet.description)) : 'Update Transaction',"ok-title":_vm.mode === 'create' ? _vm.$t('Submit') : _vm.$t('Update'),"busy":_vm.isProcessing,"no-close-on-backdrop":""},on:{"ok":_vm.handleOk,"hidden":function () { _vm.form = {}; _vm.$emit('onClose') }}},[_c('b-overlay',{attrs:{"show":_vm.isProcessing}},[_c('validation-observer',{ref:"reconciliationForm"},[_c('b-form-group',{attrs:{"label":_vm.$t('Transaction Type')}},[_c('custom-switch',{attrs:{"show-icons":false,"checked-value":true,"unchecked-value":false,"initial-value":_vm.isIncome,"left-text":_vm.$t('Expense'),"right-text":_vm.$t('Income')},model:{value:(_vm.isIncome),callback:function ($$v) {_vm.isIncome=$$v},expression:"isIncome"}})],1),_c('validation-provider',{attrs:{"name":"vendor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Select Vendor')}},[_c('v-select',{attrs:{"options":_vm.vendorOptions,"placeholder":"Filter Vendor","select-on-key-codes":[13],"clearable":false,"taggable":""},on:{"search":_vm.onVendorsFilter},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var label = ref.label;
var type = ref.type;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('b-avatar',[_c('i',{staticClass:"font-18",class:_vm.getVendorIcon(type, type)})]),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(label))])],1)]}},{key:"option",fn:function(ref){
var label = ref.label;
var type = ref.type;
return [_c('div',{staticClass:"d-flex align-items-center"},[(type)?_c('b-avatar',[_c('i',{staticClass:"font-18",class:_vm.getVendorIcon(type)})]):_c('i',{staticClass:"fas fa-plus"}),_c('div',{staticClass:"ml-1"},[_c('p',{staticClass:"font-weight-bold mb-0"},[_vm._v(" "+_vm._s(label)+" ")]),_c('span',[_vm._v(_vm._s(type))])])],1)]}},{key:"no-options",fn:function(){return [_c('i',{staticClass:"fas fa-keyboard"}),_vm._v(" "+_vm._s(_vm.$t('messages.search-vendors'))+" ")]},proxy:true},{key:"list-footer",fn:function(){return [(_vm.vendorsLoading)?_c('li',{staticStyle:{"text-align":"center"}},[_c('b-spinner',{staticClass:"my-1"})],1):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.form.vendor),callback:function ($$v) {_vm.$set(_vm.form, "vendor", $$v)},expression:"form.vendor"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('b-row',{staticClass:"justify-content-between align-items-center"},[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Transaction Date')}},[_c('b-form-datepicker',{attrs:{"date-format-options":{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' },"max":_vm.TODAY()},model:{value:(_vm.form.transactionDate),callback:function ($$v) {_vm.$set(_vm.form, "transactionDate", $$v)},expression:"form.transactionDate"}})],1)],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"amount","rules":"required|min_value:1|wz-double"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Amount')}},[_c('b-input-group',{attrs:{"prepend":_vm.currency}},[_c('b-form-input',{attrs:{"type":"number","placeholder":"Amount"},on:{"keypress":function($event){return _vm.handleAmountInput($event)}},model:{value:(_vm.form.amount),callback:function ($$v) {_vm.$set(_vm.form, "amount", $$v)},expression:"form.amount"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('validation-provider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Description')}},[_c('b-form-textarea',{attrs:{"placeholder":"Description","rows":"3"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('invoice-uploader',{on:{"document-uploaded":function (url) { return _vm.documentData = url; },"document-removed":function () { return _vm.documentData = null; }}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }