export const companyExporter = 'CompanyStatementExporter'
export const companyShareExporter = 'CompanyShareStatementExporter'

export const companyStatementQuery = `query getCompanyTransactions($first: Int, $page: Int, $startTime: DateTime!, $endTime: DateTime!, $company: String!) {
    company(uuid: $company) {
      balance: walletBalance(date: $endTime)
      openingBalance: walletBalance(
        filter: {date: $startTime, first: $first, page: $page}
      )
      walletTransactions(
        first: $first
        page: $page
        orderBy: [{column: TRANSACTION_DATE, order: ASC}, {column: ID, order: ASC}]
        between: {startTime: $startTime, endTime: $endTime}
      ) {
        total
        data {
          uuid
          amount
          description
          creator {
            uuid
            name
            __typename
          }
          createdAt
          transactionDate
          walletShare {
            uuid
            description
            wallet {
              ... on InternalWallet {
                id
                __typename
              }
              ...BankWalletFragment
              __typename
            }
            __typename
          }
          transactionType {
            ...BankTransferSuccessFragment
            ...WalletToWalletTransferFragment
            ...ReconciliationTransactionFragment
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
  fragment BankWalletFragment on BankWallet {
    id
    information {
      ... on AchBillingInformation {
        alias
        bankAccount
        __typename
      }
      ... on FxBillingInformation {
        alias
        bankAccount
        __typename
      }
      ... on BillingInformation {
        alias
        bankAccount
        __typename
      }
      __typename
    }
    __typename
  }
  fragment BankTransferSuccessFragment on BankTransferSuccess {
    id
    amount
    description
    source {
      description
      __typename
    }
    __typename
  }
  fragment WalletToWalletTransferFragment on WalletToWalletTransfer {
    document
    source {
      description
      __typename
    }
    destination {
      ... on User {
        uuid
        name
        __typename
      }
      ... on Company {
        uuid
        name
        __typename
      }
      __typename
    }
    __typename
  }
  fragment ReconciliationTransactionFragment on ReconciliationTransaction {
    id
    amount
    description
    share {
      description
      __typename
    }
    document
    __typename
  }
`

export const companyShareStatementQuery = `query getCompanyShareTransactions($company: String!, $first: Int, $page: Int, $startTime: DateTime!, $endTime: DateTime!, $keyword: [String!], $walletUid: String) {
  company(uuid: $company) {
    uuid
    name
    wallets(walletUid: $walletUid) {
      data {
        uuid
        shares(share: {keyword: $keyword, searchKey: UUID}) {
          uuid
          balance: balance(date: $endTime)
          openingBalance: balance(filter: {date: $startTime, first: $first, page: $page})
          description
          transactions(
            first: $first
            page: $page
            orderBy: [{column: TRANSACTION_DATE, order: ASC}, {column: ID, order: ASC}]
            between: {startTime: $startTime, endTime: $endTime}
          ) {
            total
            data {
              uuid
              description
              amount
              createdAt
              transactionDate
              creator{
                name
                __typename
              }
              walletShare {
                uuid
                description
                wallet {
                  ... on InternalWallet {
                    id
                    __typename
                  }
                  ...BankWalletFragment
                  __typename
                }
                __typename
              }
              transactionType {
                __typename
                ...BankTransferSuccessFragment
                ...WalletToWalletTransferFragment
              }
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
fragment BankWalletFragment on BankWallet {
  id
  information {
    ... on AchBillingInformation {
      alias
      bankAccount
      __typename
    }
    ... on FxBillingInformation {
      alias
      bankAccount
      __typename
    }
    ... on BillingInformation {
      alias
      bankAccount
      __typename
    }
    __typename
  }
  __typename
}
fragment BankTransferSuccessFragment on BankTransferSuccess {
  id
  amount
  description
  source {
    description
    __typename
  }
  __typename
}
fragment WalletToWalletTransferFragment on WalletToWalletTransfer {
  document
  source {
    description
    __typename
  }
  destination {
    ... on User {
      uuid
      name
      __typename
    }
    ... on Company {
      uuid
      name
      __typename
    }
    __typename
  }
  __typename
}
`
