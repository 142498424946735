<template>
  <div>
    <p class="small mb-0">
      From {{ transaction.transactionType.source.description }}
      <span v-if="destination">to {{ `${transaction.walletShare.wallet.information.alias} (${transaction.walletShare.wallet.information.bankAccount})` }}</span>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    transaction: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
