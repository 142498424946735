<template>
  <b-modal
    :id="`reconcile-transaction-${index}-${userType}-modal`"
    :title="mode === 'create' ? `Add Manual Transaction for ${wallet.description}` : 'Update Transaction'"
    :ok-title="mode === 'create' ? $t('Submit') : $t('Update')"
    :busy="isProcessing"
    no-close-on-backdrop
    @ok="handleOk"
    @hidden="() => { form = {}; $emit('onClose') }"
  >
    <b-overlay :show="isProcessing">
      <validation-observer ref="reconciliationForm">
        <!-- Transaction Type -->
        <b-form-group :label="$t('Transaction Type')">
          <custom-switch
            v-model="isIncome"
            :show-icons="false"
            :checked-value="true"
            :unchecked-value="false"
            :initial-value="isIncome"
            :left-text="$t('Expense')"
            :right-text="$t('Income')"
          />
        </b-form-group>

        <!-- Vendors List -->
        <validation-provider
          #default="{ errors }"
          name="vendor"
          rules="required"
        >
          <b-form-group :label="$t('Select Vendor')">
            <v-select
              v-model="form.vendor"
              :options="vendorOptions"
              placeholder="Filter Vendor"
              :select-on-key-codes="[13]"
              :clearable="false"
              taggable
              @search="onVendorsFilter"
            >
              <!-- Selected Option -->
              <template #selected-option="{ label, type }">
                <div class="d-flex align-items-center">
                  <b-avatar>
                    <i
                      :class="getVendorIcon(type, type)"
                      class="font-18"
                    />
                  </b-avatar>
                  <span class="ml-1">{{ label }}</span>
                </div>
              </template>

              <!-- Options -->
              <template #option="{ label, type }">
                <div class="d-flex align-items-center">
                  <b-avatar v-if="type">
                    <i
                      :class="getVendorIcon(type)"
                      class="font-18"
                    />
                  </b-avatar>
                  <i
                    v-else
                    class="fas fa-plus"
                  />
                  <div class="ml-1">
                    <p class="font-weight-bold mb-0">
                      {{ label }}
                    </p>
                    <span>{{ type }}</span>
                  </div>
                </div>
              </template>

              <template #no-options>
                <i class="fas fa-keyboard" />
                {{ $t('messages.search-vendors') }}
              </template>

              <template #list-footer>
                <li
                  v-if="vendorsLoading"
                  style="text-align: center"
                >
                  <b-spinner class="my-1" />
                </li>
              </template>

            </v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <b-row class="justify-content-between align-items-center">
          <!-- Transaction Date -->
          <b-col md="6">
            <b-form-group :label="$t('Transaction Date')">
              <b-form-datepicker
                v-model="form.transactionDate"
                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                :max="TODAY()"
              />
            </b-form-group>
          </b-col>

          <!-- Amount -->
          <b-col>
            <validation-provider
              #default="{errors}"
              name="amount"
              rules="required|min_value:1|wz-double"
            >
              <b-form-group :label="$t('Amount')">
                <b-input-group :prepend="currency">
                  <b-form-input
                    v-model="form.amount"
                    type="number"
                    placeholder="Amount"
                    @keypress="handleAmountInput($event)"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <!-- Description -->
        <validation-provider
          #default="{ errors }"
          name="description"
          rules="required"
        >
          <b-form-group :label="$t('Description')">
            <b-form-textarea
              v-model="form.description"
              placeholder="Description"
              rows="3"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <!-- Document -->
        <invoice-uploader
          @document-uploaded="url => documentData = url"
          @document-removed="() => documentData = null"
        />
      </validation-observer>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BRow, BCol, BModal, BFormGroup, BFormInput, BInputGroup, BOverlay, BFormTextarea, BFormDatepicker, BSpinner, BAvatar,
} from 'bootstrap-vue'
import {
  computed,
  reactive, toRefs, watch, getCurrentInstance,
} from '@vue/composition-api'
import Swal from 'sweetalert2'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import InvoiceUploader from '@/views/common/components/InvoiceUploader.vue'
import CustomSwitch from '@/views/common/components/CustomSwitch.vue'
import useApollo from '@/plugins/graphql/useApollo'
import vSelect from 'vue-select'
import _ from 'lodash'
import useWallet from '@/utils/wallet'

const { getVendorIcon } = useWallet()

extend('wz-double', value => {
  if (typeof value !== 'number') {
    const decimals = value.split('.')[1]
    if (decimals && decimals.length > 2) return 'The amount should have atmost 2 decimal points'
  }
  return true
})

export default {
  components: {
    BRow,
    BCol,
    BModal,
    BOverlay,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BFormTextarea,
    BFormDatepicker,
    BSpinner,
    BAvatar,
    InvoiceUploader,
    CustomSwitch,
    ValidationObserver,
    ValidationProvider,
    vSelect,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    wallet: {
      type: Object,
      default: () => {},
    },
    parentWallet: {
      type: String,
      default: () => '',
    },
    userType: {
      type: String,
      default: () => 'users',
    },
    mode: {
      type: String,
      default: () => 'create',
    },
    selectedTransaction: {
      type: Object,
      default: () => null,
    },
  },
  setup(props, context) {
    const instance = getCurrentInstance().proxy
    const store = instance.$store

    const state = reactive({
      isEntireFamily: false,
      currency: store.state.project.company.currency,
      source: {},
      form: {},
      documentData: null,
      isProcessing: false,
      isIncome: true,
      vendors: [],
      vendorOptions: [],
      vendorsLoading: false,
      selectedVendor: null,
    })

    const company = computed(() => store.state.project.selectedCompany)

    watch(() => props.selectedTransaction, value => {
      if (value) {
        const {
          uuid, transactionDate, amount, description, documentData, vendorInfo,
        } = value
        state.form = {
          uuid,
          transactionDate,
          amount,
          description,
          documentData,
          vendor: {
            uuid: vendorInfo.vendor.uuid,
            label: vendorInfo.vendor.name,
            type: vendorInfo.vendor.__typename,
          },
        }
        state.form.amount = Math.abs(state.form.amount)
        state.isIncome = amount >= 0
      }
    })

    const createNewVendor = () => {
      const params = {
        companyUid: company.value,
        name: state.form.vendor.label ?? state.form.vendor,
      }

      useApollo.company.createNewCompanyVendor(params).then(response => {
        state.selectedVendor = response.data.createNewCompanyVendor.data.uuid
      })
    }

    watch(() => state.form.vendor, value => {
      if (value && !value.type) {
        Swal.fire({
          width: '600px',
          title: instance.$t('messages.add-vendor'),
          html: `Confirm to proceed and create <span class="font-weight-bold text-decoration-underline">${value.label ?? value}</span>`,
          iconHtml: '<i class="fas fa-user-tie" />',
          iconColor: '#28c76f',
          confirmButtonText: instance.$t('Confirm'),
          showCancelButton: true,
          cancelButtonText: instance.$t('Cancel'),
          cancelButtonColor: '#ea5455',
        }).then(response => {
          if (response.isConfirmed) {
            createNewVendor()
          } else if (response.isDenied || response.isDismissed) {
            state.form.vendor = ''
          }
        })
      }
    })

    const handleAmountInput = event => {
      const inputValue = event.target.value
      const decimalParts = inputValue.split('.')

      if (decimalParts.length > 1 && decimalParts[1].length > 1) {
        event.preventDefault()
      }
      state.form.amount = event.target.value
    }

    const updateReconciliation = () => {
      state.isProcessing = true
      const {
        uuid, amount, transactionDate, documentData, vendor, ...form
      } = state.form

      const params = {
        input: {
          ...form,
          amount: Number(amount),
          transactionDate: context.root.FORMAT_DATE(transactionDate, 'YYYY-MM-DD HH:mm:SS'),
        },
        transactionType: state.isIncome ? 'DEPOSIT' : 'WITHDRAW',
        companyUid: company.value,
        transactionUid: uuid,
        action: 'UPDATE',
        documentData: state.documentData,
        vendor: {
          vendorType: vendor.type?.toUpperCase() ?? 'VENDOR',
          uuid: state.selectedVendor ?? vendor.uuid,
        },
      }

      useApollo.company.updateCompanyReconciliationTransaction(params).then(response => {
        context.root.showSuccessMessage({
          data: {
            message: response.data.updateCompanyReconciliationTransaction.message,
          },
        })
        context.emit('reconciliation-success')
      }).catch(error => {
        context.root.showErrorMessage(error)
      }).finally(() => {
        state.isProcessing = false
        context.root.$bvModal.hide(`reconcile-transaction-${props.index}-${props.userType}-modal`)
      })
    }

    const makeReconciliation = () => {
      const { vendor, ...form } = state.form
      state.isProcessing = true
      let api = 'makeReconciliationTransaction'
      const params = {
        source: {
          walletUid: props.parentWallet,
          shareUid: props.wallet.uuid,
        },
        input: {
          ...form,
          amount: Number(state.form.amount),
          transactionDate: context.root.FORMAT_DATE(state.form.transactionDate, 'YYYY-MM-DD HH:mm:ss'),
        },
        documentData: state.documentData,
        transactionType: state.isIncome ? 'DEPOSIT' : 'WITHDRAW',
      }

      if (props.userType === 'company') {
        params.companyUid = store.state.project.selectedCompany
        params.vendor = {
          vendorType: vendor?.type?.toUpperCase() ?? 'VENDOR',
          uuid: state.selectedVendor ?? vendor?.uuid,
        }

        api = 'makeCompanyReconciliationTransaction'
      }

      useApollo[props.userType][api](params).then(response => {
        context.root.showSuccessMessage({
          data: {
            message: response.data[api].message,
          },
        })
        context.emit('reconciliation-success')
      }).catch(error => {
        context.root.showErrorMessage(error)
      }).finally(() => {
        state.isProcessing = false
        context.root.$bvModal.hide(`reconcile-transaction-${props.index}-${props.userType}-modal`)
      })
    }

    const handleOk = bvModal => {
      bvModal.preventDefault()
      context.refs.reconciliationForm.validate().then(success => {
        if (success) {
          state.isProcessing = true
          if (props.userType === 'company' && props.mode === 'update') updateReconciliation()
          else makeReconciliation()
        }
      })
    }

    const getVendors = _.debounce(value => {
      const params = {
        companyUid: company.value,
        keyword: value,
      }

      useApollo.company.getCompanyVendors(params).then(response => {
        state.vendorOptions = response.data.company.vendors.data.map(({ vendor }) => ({
          uuid: vendor.uuid,
          label: vendor.name,
          type: vendor.__typename,
        }))
      }).finally(() => {
        state.vendorsLoading = false
      })
    }, 1000)

    const onVendorsFilter = value => {
      if (value) {
        state.vendorsLoading = true
        getVendors(value)
      }
    }

    return {
      getVendorIcon,
      ...toRefs(state),
      handleAmountInput,
      handleOk,
      onVendorsFilter,
    }
  },
}
</script>

<style lang="scss">
#swal2-title {
  color: #777 !important;
}
.swal2-cancel {
  margin-left: 12px;
}
.text-decoration-underline {
  text-decoration: underline;
  text-decoration-style: dotted;
}
</style>
