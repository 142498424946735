<template>
  <div>
    <p class="small mb-0">
      {{ transaction.walletShare.description }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    transaction: {
      type: Object,
      default: () => {},
    },
    userType: {
      type: String,
      default: () => 'users',
    },
  },
}
</script>
